<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">物流服务</div>
      <div class="h2">诚信物流，品质仓储</div>
    </div>
    <div class="zhuti"  style="background: #fff">
      <avue-search :option="option"   @change="loadlist(true)"
                   v-model="form">
        <template #name="{}">
          <el-col :span="6">
            <avue-input v-model="form1.name" placeholder="请输入" ></avue-input>
          </el-col>
        </template>
      </avue-search>
      <div style="text-align: right;padding: 0 20px 20px 0">
        <el-button type="primary" size="mini" @click="loadlist(true)">筛选</el-button>
        <el-button  size="mini" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="zhuti">
      <div class="news" v-for="(ar,index) in arr" :key="index">
        <div class="flex">
          <div class="flex-1 title ellipsis2">{{ar.carTypeName}}</div>
        </div>
        <div class="desc">
          <div>地点：{{ar.departProvinceName}}{{ar.departCityName}}{{ar.departAreaName}}{{ar.departAddress}}</div>
          <div>时间：{{ar.createTime}}</div>
          <div>
            <el-button size="small" class="button" type="primary"  @click="goxq(ar)">查看详情</el-button>
          </div>
        </div>
      </div>

    </div>
    <div class="zhuti">
      <div class="fenye">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="page.current"
            :page-sizes="[12, 50, 100, 200]"
            :page-size="page.size"
            :total="page.total">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import {fiveyears} from "../../utils";
import {logisticsindex, logisticspage, warehouseindex, warehouselist} from "../../request/moudle/logistics";
export default {
  name: "zchj",
  data(){
    return {
      keyword:'',
      form:{
        typeId:Number(this.$route.query.typeId)
      },
      form1:{
        name:''
      },
      page:{
        current:1,
        size:12,
        total:0
      },
      arr:[],
      option: {
        column: [{
          label: '类型',
          dicData:[],
          prop: 'typeId',
          multiple: false,
          props: {
            label: 'title',
            value: 'id'
          },
        },{
          label: '车辆类型',
          prop: 'carTypeId',
          props: {
            label: 'title',
            value: 'id',
          },
          multiple: false,
          dicData:[],
        }
          ]
      },
    }
  },
  created() {
    this.loadconfig()
    this.loadlist()
  },
  methods:{
    reset(){
      for (const val of this.option.column){
        if(val.multiple){
          this.form[val.prop]=['']
        }else {
          this.form[val.prop]=''
        }
      }
      this.form.typeId=1
      this.form1.name=''
    },
    goxq(item){
      this.$router.push({
        path:'/wlxq',
        query:{
          id:item.id,
          typeId:this.form.typeId,
        }
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        ...this.form,
        ...this.form1
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.logistics.logisticspage(obj).then((res)=>{
        this.arr=res.data.records
        this.page.total=res.data.total
      })
    },
    loadconfig(){
      this.$api.logistics.logisticsindex().then((res)=>{
        const type=this.findObject(this.option.column, 'typeId')
        const carTypeId=this.findObject(this.option.column, 'carTypeId')
        type.dicData=res.data.typeList
        carTypeId.dicData=res.data.carTypeList
        carTypeId.dicData.unshift({ title: '不限', id: '' })
      })
    },
    handleSizeChange(val){
      this.page.size=val
      this.loadlist()
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
  }
}
</script>

<style lang="scss" scoped>
.fenye{
  text-align: center;
  padding: 30px 0;
}
.zchjs{
  background: #F5F5F5;
}
.jiedu{
  font-weight: bolder;
  margin-top: 10px;
  span{
    margin: 0 10px;
  }
}
.artime{
  display: flex;
  align-items: flex-end;
}
.news{
  background: #fff;
  color: #3B3E45;
  overflow: hidden;
  width: 340px;
  height: 125px;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  float: left;
  margin-left: 10px;
  .state{
    width: 80px;
    text-align: right;
    margin-left: 15px;
  }
  .image{
    width: 280px;
    height: 200px;
    margin-right: 15px;
  }
  .desc{
    color: #3F3F3F;
    font-size: 12px;
    overflow: hidden;
    position: relative;
    div{
      padding: 5px 0;
    }
  }
  .button{
    float: right;
  }
  .title{
    height: 35px;
    line-height: 22px;
    font-weight: bolder;
  }
  .biaoqian{
    background:linear-gradient(0deg,#0078F2,#00BAFF);
    padding: 0px 8px;
    margin-right: 10px;
    border-radius: 4px;
    color: #fff;
  }
}
.fenye{
  text-align: center;
  padding: 30px 0;
}
.header{
  height: 220px;
  background: url("../../assets/banner/logistics.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0 auto;
  margin-top: 62px;
  .left{
    width: 535px;
    margin-right: 25px;
    color: #fff;
    .cont{
      height: 249px;
    }
    .title{
      background: linear-gradient(0deg,#0078F2,#00BAFF);
      font-size: 18px;
      font-weight: 500;
      padding: 13px 26px;
      span{
        float: right;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
}
.zhuti{
  width: 1200px;
  margin:30px auto;
  overflow: hidden;
  .sxx{
    background: #fff;
    padding: 20px;
  }
}
.title1{
  width: 1200px;
  margin:30px auto;
  position: relative;
  font-size: 20px;
  font-weight:550;
  color:#000000 ;
  span{
    border-top: 1px solid #000000;
    margin: 0 15px;
    margin-top: 14px;
  }
}
</style>